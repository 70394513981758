import { Box } from "@mui/material";

export interface Props {
  color: string;
}

export default function FullscreenBackground({ color }: Props) {
  return (
    <Box
      sx={{
        bgcolor: color,
        height: "100%",
        left: 0,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: -1,
      }}
    />
  );
}
