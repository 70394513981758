import { Trans, useTranslation } from "react-i18next";
import DefaultError from "../../../../../Common/components/DefaultError";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import WatermarkIcon from "../../../../../Common/components/WatermarkIcon";
import InformationBox from "../../../../../Common/components/InformationBox";
import IconLoader from "../../../../../Common/components/IconLoader";
import { HealthMonitorCodes } from "@binah/web-sdk";

interface GenericErrorProps {
  onComeBackLater: Function;
  onTryAgain: Function;
  errorCode: HealthMonitorCodes;
}

export default function GenericError({
  onComeBackLater,
  onTryAgain,
  errorCode,
}: GenericErrorProps) {
  const { t } = useTranslation();

  function getErrorCodeSubTitle(errorCode: HealthMonitorCodes) {
    switch (errorCode) {
      case HealthMonitorCodes.LICENSE_CODE_NETWORK_TIMEOUT_ERROR:
      case HealthMonitorCodes.LICENSE_CODE_NETWORK_ISSUES_ERROR:
        return t("BinahScanFlow.GenericError.subtitle.networkIssues");

      case HealthMonitorCodes.MEASUREMENT_CODE_INVALID_MEASUREMENT_AVERAGE_DETECTION_RATE_ERROR:
      case HealthMonitorCodes.MEASUREMENT_CODE_INVALID_RECENT_DETECTION_RATE_ERROR:
        return t("BinahScanFlow.GenericError.subtitle.lowResources");

      case HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_ERROR:
        return t("BinahScanFlow.GenericError.subtitle.faceNotDetected");

      default:
        return t("BinahScanFlow.GenericError.subtitle.default");
    }
  }

  function getErrorCodeInstructions(errorCode: HealthMonitorCodes) {
    switch (errorCode) {
      case HealthMonitorCodes.LICENSE_CODE_NETWORK_TIMEOUT_ERROR:
      case HealthMonitorCodes.LICENSE_CODE_NETWORK_ISSUES_ERROR:
        return t<any, any, string[]>(
          "BinahScanFlow.GenericError.instructions.networkIssues",
          {
            returnObjects: true,
          }
        ) as [];
      case HealthMonitorCodes.MEASUREMENT_CODE_INVALID_MEASUREMENT_AVERAGE_DETECTION_RATE_ERROR:
      case HealthMonitorCodes.MEASUREMENT_CODE_INVALID_RECENT_DETECTION_RATE_ERROR:
        return t<any, any, string[]>(
          "BinahScanFlow.GenericError.instructions.lowResources",
          {
            returnObjects: true,
          }
        ) as [];
      case HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_ERROR:
        return t<any, any, string[]>(
          "BinahScanFlow.GenericError.instructions.faceNotDetected",
          {
            returnObjects: true,
          }
        ) as [];
      default:
        return [];
    }
  }

  return (
    <DefaultError
      icon={
        <IconLoader sx={{ fontSize: 30 }} color="primary" icon="NoticeIcon" />
      }
      onComeBackLater={onComeBackLater}
      onTryAgain={onTryAgain}
    >
      <Typography variant="h2" textAlign="center">
        {t("BinahScanFlow.GenericError.title")}
      </Typography>
      <Typography variant="h3" textAlign="center">
        {getErrorCodeSubTitle(errorCode)}
      </Typography>

      {getErrorCodeInstructions(errorCode).length > 0 && (
        <Typography variant="h3">
          {t("BinahScanFlow.GenericError.instructions.title")}
        </Typography>
      )}

      <Stack component="ul" role="list">
        {getErrorCodeInstructions(errorCode).map((listItemText: string) => (
          <ListItem
            role="listitem"
            sx={{ padding: "2px 0" }}
            key={listItemText}
          >
            <ListItemIcon sx={{ display: "contents" }}>
              <WatermarkIcon
                height={22}
                width={23}
                color="primary.900"
                foreground="info"
                Icon={"CheckmarkIcon"}
              />
            </ListItemIcon>
            <ListItemText sx={{ pl: 2 }}>{listItemText}</ListItemText>
          </ListItem>
        ))}
      </Stack>

      <Stack spacing={2}>
        <InformationBox Icon={"PadlockIcon"}>
          <Stack spacing={2}>
            <Typography variant="body2" fontWeight="600">
              {t("BinahScanFlow.GenericError.supportTip.title")}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="BinahScanFlow.GenericError.supportTip.body"></Trans>
            </Typography>
          </Stack>
        </InformationBox>
        <Typography variant="body2" textAlign="center">
          {t("BinahScanFlow.GenericError.footer.errorCode", {
            errorCode: errorCode,
          })}
        </Typography>
      </Stack>
    </DefaultError>
  );
}
