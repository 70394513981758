import { useTranslation } from "react-i18next";
import DefaultError from "../../../../../Common/components/DefaultError";
import { Typography } from "@mui/material";

interface PermissionIssuesErrorProps {
  onComeBackLater: Function;
  onTryAgain: Function;
}

export default function PermissionIssuesError({
  onComeBackLater,
  onTryAgain,
}: PermissionIssuesErrorProps) {
  const { t } = useTranslation();

  return (
    <DefaultError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain}>
      <Typography variant="h2">{t("PermissionIssuesError.title")}</Typography>
      <Typography pb={2}>{t("PermissionIssuesError.content")}</Typography>
    </DefaultError>
  );
}
