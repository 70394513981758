import { Stack } from "@mui/material";
import AwaitCameraPermissions from ".";

export default function AwaitCameraPermissionsExamples() {
  function onBackButtonClick() {
    console.log("onBackButtonClick");
  }

  return (
    <Stack sx={{ height: "100vh", p: 2 }}>
      <AwaitCameraPermissions onBackButtonClick={onBackButtonClick} />
    </Stack>
  );
}
