import {
  Box,
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Link,
  Typography,
  Fade,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import InstructionsImage from "./instructions-cover.png";
import CircledBackButton from "../../../../../../../Common/components/CircledBackButton";
import { trackEvent } from "../../../../../../../../services/analytics-adapter";
import FullScreenIframeDialog from "../../../../../../../Common/components/FullScreenIframeDialog";
import { useState } from "react";
import { generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage } from "../../../../../../../../services/core-api-adapter";
import { convertPxToRem } from "../../../../../../../../utils";
import IconLoader from "../../../../../../../Common/components/IconLoader";

interface DefaultProps {
  onComeBackLater: () => void;
  onConfirm?: () => void;
  onBackButtonClick?: () => void;
  shouldHideCTAs?: boolean;
}

interface ListWrapperProps {
  headingText: string;
  instructions: string[];
  icon: any;
  listItemTextColor?: string;
}

function ListWrapper({
  instructions,
  headingText,
  icon,
  listItemTextColor,
}: ListWrapperProps) {
  return (
    <Stack spacing={2}>
      <Typography variant="h2">{headingText}</Typography>
      <Stack
        component="ul"
        role="list"
        aria-label={`${headingText} instruction list`}
      >
        {instructions.map((listItemText) => (
          <ListItem
            role="listitem"
            sx={{ padding: "2px 0", alignItems: "start" }}
            key={listItemText}
          >
            <ListItemIcon sx={{ minWidth: "22px" }}>{icon}</ListItemIcon>
            <ListItemText sx={{ pl: 2 }}>
              <Typography color={listItemTextColor} fontWeight="600">
                {listItemText}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
}

export default function Default({
  onConfirm,
  onComeBackLater,
  onBackButtonClick,
  shouldHideCTAs = false,
}: DefaultProps) {
  const { t } = useTranslation();
  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onComeBackLaterButtonClick() {
    trackEvent({
      event: "action.healthCheckFaceScanSkipped",
      source: "health check",
    });
    onComeBackLater();
  }

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "health check",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }

  return (
    <Stack
      p={shouldHideCTAs ? 0 : 2}
      spacing={4}
      sx={{
        height: "100%",
        overflowX: "hidden",
      }}
    >
      {shouldHideCTAs === false && (
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>
      )}

      <Stack spacing={1} my={2}>
        {shouldHideCTAs === false && (
          <Typography variant="h2" fontWeight={200}>
            {t("BinahScanFlow.instructions.title")}
          </Typography>
        )}
        <Typography variant="body2" color="neutral.500">
          {t("BinahScanFlow.instructions.subtitle")}
        </Typography>
      </Stack>
      <Fade in={true}>
        <Box
          component="img"
          src={InstructionsImage}
          alt={t("BinahScanFlow.instructions.title")}
          alignSelf="center"
          sx={{
            width: "180px",
            my: 2,
          }}
        />
      </Fade>
      <Stack spacing={3} sx={{ flex: 2 }}>
        <Box>
          <ListWrapper
            headingText={t("BinahScanFlow.instructions.set1Title")}
            listItemTextColor="neutral.main"
            icon={
              <Box
                bgcolor="neutral.900"
                borderRadius="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={22}
                width={22}
                mt="7px"
              >
                <IconLoader
                  color="info"
                  icon="CheckmarkIcon"
                  sx={{ fontSize: convertPxToRem(15) }}
                />
              </Box>
            }
            instructions={
              t("BinahScanFlow.instructions.set1", {
                returnObjects: true,
              }) || []
            }
          />
        </Box>
        <Box>
          <ListWrapper
            headingText={t("BinahScanFlow.instructions.set2Title")}
            listItemTextColor="neutral.500"
            icon={
              <Box
                bgcolor="neutral.700"
                borderRadius="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={22}
                width={22}
                mt="7px"
              >
                <IconLoader
                  color="info"
                  icon="CrossIcon"
                  sx={{ fontSize: convertPxToRem(15) }}
                />
              </Box>
            }
            instructions={
              t("BinahScanFlow.instructions.set2", {
                returnObjects: true,
              }) || []
            }
          />
        </Box>
      </Stack>

      <Stack pt={2} spacing={2}>
        <Divider />
        <Typography variant="h2">
          {t("BinahScanFlow.instructions.disclaimer.title")}
        </Typography>
        <Typography variant="body1">
          {t("BinahScanFlow.instructions.disclaimer.subtitle")}
        </Typography>
        <Typography variant="body1" color="inherit">
          <Trans i18nKey="BinahScanFlow.instructions.disclaimer.footer">
            <Link
              sx={{ color: "inherit", fontWeight: 900 }}
              href="/"
              onClick={onTermsLinkClick}
            ></Link>
          </Trans>
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          fullWidth
          onClick={onComeBackLaterButtonClick}
        >
          {t("common.comeBackButton")}
        </Button>

        {shouldHideCTAs === false && (
          <Button fullWidth onClick={onConfirm}>
            {t("common.continueButton")}
          </Button>
        )}
      </Stack>
      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
    </Stack>
  );
}
