import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
import { defaultFullscreenPageStyling } from "../../../../theme";
import ProcessIndicatorIcon from "../ProcessIndicatorIcon";
import { useNavigate } from "react-router";

interface DefaultErrorProps {
  children: any;
  onTryAgain?: Function;
  onComeBackLater?: Function;
  icon?: any;
}

export default function DefaultError({
  children,
  onComeBackLater,
  onTryAgain,
  icon,
}: DefaultErrorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onHandleTryAgain() {
    if (typeof onTryAgain === "function") {
      return onTryAgain();
    }

    return navigate(-1);
  }

  return (
    <Stack
      justifyContent="space-between"
      flexDirection="row"
      sx={{
        ...defaultFullscreenPageStyling,
      }}
    >
      <Stack width="100%" spacing={2} justifyContent="flex-end">
        <Stack
          sx={{
            opacity: 0.5,
            textAlign: "center",
            alignItems: "center",
            mb: 6,
          }}
        >
          {icon ? (
            icon
          ) : (
            <ProcessIndicatorIcon type="error" height={90} width={90} />
          )}
        </Stack>
        {children}
        <Stack direction="row" spacing={2}>
          {onComeBackLater && (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => onComeBackLater()}
            >
              {t("common.comeBackButton")}
            </Button>
          )}
          {onTryAgain && (
            <Button
              fullWidth
              onClick={() => onHandleTryAgain()}
              variant="contained"
            >
              {t("common.tryAgain")}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
