import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  useCameras,
  useDisableZoom,
  useError,
  useMonitor,
} from "../../../../hooks";
import { Box, Stack } from "@mui/material";
import { SessionState, isIos } from "@binah/web-sdk";
import AwaitCameraPermissions from "../AwaitCameraPermissions";
import { HealthCheckQuestionnaireData } from "../../../../../../services/core-api-adapter";

function useIsScannerMounted() {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);
  return () => isMounted.current;
}

interface ScannerProps {
  onError: Function;
  onAlert: Function;
  onScanReady: Function;
  onScanComplete: Function;
  onStartMeasuring: boolean;
  measurementDuration: number;
  onBackButton: Function;
  healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  onVitalSignsChanged?: Function;
}

function getObjectFit() {
  if (isIos()) {
    return "unset";
  }
  return "cover";
}

export default function Scanner({
  onVitalSignsChanged,
  onError,
  onAlert,
  onStartMeasuring,
  measurementDuration,
  onScanReady,
  onScanComplete,
  healthCheckQuestionnaireData,
  onBackButton,
}: ScannerProps) {
  const cameras = useCameras();
  const [cameraId, setCameraId] = useState<string>("");
  const [videoStreamTrack, setVideoStreamTrack] = useState<any>();
  const licenseKey = import.meta.env.VITE_APP_BINAH_AI_SDK_LICENSE_KEY || "";
  const videoRef = useRef<HTMLVideoElement>(null);

  const mounted = useIsScannerMounted();
  useDisableZoom();

  const { sessionState, vitalSigns, error, info, isScanComplete } = useMonitor(
    videoRef as any,
    cameraId,
    measurementDuration,
    licenseKey,
    onStartMeasuring,
    healthCheckQuestionnaireData
  );

  const errorMessage = useError(error);
  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState]
  );

  useEffect(() => {
    if (cameras?.length) {
      setCameraId(cameras[0].deviceId);
    }
  }, [cameras]);

  useEffect(() => {
    const videoElement = document.getElementById("video") as any;
    const stream = videoElement.srcObject;

    if (stream) {
      const tracks = stream.getTracks();
      setVideoStreamTrack(tracks[0]);
    }

    return () => {
      if (!mounted() && videoStreamTrack) {
        videoStreamTrack.stop();
      }
    };
  }, [mounted]);

  useEffect(() => {
    if (error) {
      onError(error);
    }

    if (info) {
      onAlert(info);
    }
  }, [error, info]);

  useEffect(() => {
    if (sessionState === SessionState.ACTIVE) {
      onScanReady();
    }

    if (isMeasuring() && onVitalSignsChanged) {
      onVitalSignsChanged(vitalSigns);
    }

    if (isScanComplete === true && Object.keys(vitalSigns || {})?.length) {
      onScanComplete(vitalSigns);
    }
  }, [vitalSigns, sessionState, errorMessage, measurementDuration]);

  return (
    <>
      {!sessionState && (
        <Stack
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 4,
          }}
        >
          <AwaitCameraPermissions onBackButtonClick={onBackButton} />
        </Stack>
      )}
      <Stack
        sx={{
          height: "100%",
          top: 0,
          zIndex: 0,
        }}
      >
        <>
          <Box
            sx={{
              position: "fixed",
              top: "20%",
              left: "50%",
              transform: "translate(-50%, 0)",
              borderRadius: "100%",
              width: "360px",
              height: "360px",
              boxShadow: "0px 0px 0px 2000px rgba(0,0,0,.4)",
            }}
          />
          <video
            ref={videoRef}
            id="video"
            muted={true}
            playsInline={true}
            style={{
              transform: "scaleX(-1)",
              width: "100%",
              height: "100%",
              objectFit: getObjectFit(),
            }}
          />
        </>
      </Stack>
    </>
  );
}

export const MemoizedScanner = memo(Scanner);
