import UnsupportedDeviceError from ".";

export default function UnsupportedDeviceErrorExamples() {
  return (
    <>
      <UnsupportedDeviceError
        deviceOS="ANDROID_OS"
        onComeBackLater={() => {}}
        onTryAgain={() => {}}
      />
      <UnsupportedDeviceError
        deviceOS="IOS"
        onComeBackLater={() => {}}
        onTryAgain={() => {}}
      />
    </>
  );
}
