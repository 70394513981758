import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@mui/material";

interface DefaultToggleButtonGroupProps extends ToggleButtonGroupProps {
  options: {
    name: string;
    value: any;
  }[];
  value: any;
}

export default function DefaultToggleButtonGroup({
  options,
  value,
  ...props
}: DefaultToggleButtonGroupProps) {
  return (
    <ToggleButtonGroup value={value} {...props}>
      {options.map(function (option, index) {
        return (
          <ToggleButton key={String(option.name + index)} value={option.value}>
            {option.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
