import { Box, SxProps, Theme } from "@mui/material";
import IconLoader from "../IconLoader";

const SIZE_SMALL = "30%";
const SIZE_MEDIUM = "60%";
const SIZE_LARGE = "90%";

const iconSizeMap = {
  small: {
    height: SIZE_SMALL,
    width: SIZE_SMALL,
  },
  medium: {
    height: SIZE_MEDIUM,
    width: SIZE_MEDIUM,
  },
  large: {
    height: SIZE_LARGE,
    width: SIZE_LARGE,
  },
};

interface WatermarkIconProps {
  Icon: string;
  color: string | undefined;
  foreground: string | undefined;
  height?: number;
  width?: number;
  iconSize?: "small" | "medium" | "large";
  extra?: SxProps<Theme>;
}

export default function WatermarkIcon({
  color,
  foreground,
  height = 24,
  width = 24,
  iconSize = "medium",
  Icon,
  extra,
}: WatermarkIconProps) {
  const Background = IconLoader;
  const styling = { ...extra };

  return (
    <Box
      sx={{
        display: "inline-block",
        lineHeight: 0,
        position: "relative",
        width: "fit-content",
        height: { height },
        ...styling,
      }}
    >
      <Background
        icon="HandDrawnCircleIcon"
        sx={{
          height: height,
          width: width,
          color: color,
        }}
      />

      <IconLoader
        icon={Icon}
        color={foreground as any}
        sx={{
          color: foreground,
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          ...iconSizeMap[iconSize],
        }}
      />
    </Box>
  );
}
