import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { convertBooleanLikeStringToBoolean } from "../../../../utils";
import DefaultToggleButtonGroup from "../DefaultToggleButtonGroup";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface Props {
  data?: string;
  onChange?: (data: any) => void;
}

const getValueFromProps = (props: Props) => {
  return convertBooleanLikeStringToBoolean(props?.data);
};

export default function QuestionTypeSmokingStatus(props: Props) {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState<boolean | null>(
    getValueFromProps(props)
  );

  const options = [
    {
      name: t("common.yes"),
      value: true,
    },
    {
      name: t("common.no"),
      value: false,
    },
  ];

  useEffect(() => {
    setInternalValue(getValueFromProps(props));
  }, [props?.data]);

  useEffect(() => {
    const isValid = internalValue !== null;
    if (props.onChange) {
      props.onChange({
        data: {
          value: internalValue === true ? "Yes" : "No",
        },
        isValid,
      });
    }
  }, [internalValue]);

  function onToggleChange(_event: any, value: any) {
    setInternalValue(value);
    trackUserInteraction({
      linkText: `Edit Smoking status | ${value === true ? "Yes" : "No"}`,
      linkIntent: "confirmational",
      linkScope: "button",
    });
  }

  return (
    <Stack spacing={2} justifyContent="space-between">
      <Stack spacing={2}>
        <Typography variant="h3">
          {t("QuestionTypeSmokingStatus.title")}
        </Typography>
        <DefaultToggleButtonGroup
          options={options}
          value={internalValue}
          onChange={onToggleChange}
          fullWidth
          exclusive
          color="neutral"
        />
      </Stack>
    </Stack>
  );
}
