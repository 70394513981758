import { useTranslation } from "react-i18next";
import DefaultError from "../../../../../Common/components/DefaultError";
import { Stack, Typography } from "@mui/material";

interface UnsupportedDeviceErrorProps {
  onComeBackLater: Function;
  deviceOS: "ANDROID_OS" | "IOS";
  onTryAgain?: Function;
}

export default function UnsupportedDeviceError({
  onComeBackLater,
  onTryAgain,
  deviceOS,
}: UnsupportedDeviceErrorProps) {
  const { t } = useTranslation();

  return (
    <DefaultError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain}>
      <Typography variant="h2">{t("GenericError.title")}</Typography>
      <Typography>{t("UnsupportedDeviceError.subtitle")}</Typography>
      <Stack pl={3} py={2}>
        <ul>
          {(
            t<any, any, string[]>(
              `UnsupportedDeviceError.${deviceOS}.content`,
              {
                returnObjects: true,
              }
            ) as []
          ).map((text: string, index: number) => (
            <li key={String(text + index)}>
              <Typography>{text}</Typography>
            </li>
          ))}
        </ul>
      </Stack>
    </DefaultError>
  );
}
