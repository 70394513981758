import React, { useEffect, useState, useId } from "react";
import { TextField, Stack, InputLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styleInputDefault } from "../../../../theme";
import {
  clampNumber,
  clampDate,
  normaliseStringToNumber,
} from "../../../../utils";

export interface DOBFormData {
  day: string;
  month: string;
  year: string;
}

type DateOfBirthFragmentName = "day" | "month" | "year";

interface DateOfBirthInputProps {
  value?: string;
  onChange?: Function;
  showLabel?: boolean;
  disabled?: boolean;
  placeholder?: {
    day: string;
    month: string;
    year: string;
  };
  isError?: boolean;
  errorText?: string;
}

const MINIMUM_DATE_OF_BIRTH = "1900-01-01";
const MINIMUM_MONTH = "01";
const MINIMUM_DAY = "01";

function getDaysInMonth(year: string, month: string) {
  return new Date(Number(year), Number(month), 0).getDate();
}

export default function DateOfBirthInput(props: DateOfBirthInputProps) {
  const inputIDs = {
    day: useId(),
    month: useId(),
    year: useId(),
  };
  const { t } = useTranslation();
  const passedDate = new Date(props.value || "");
  const isPassedDateValid = !isNaN(passedDate.valueOf());
  const shouldShowLabel = props.showLabel !== false;

  const [dobData, setDobData] = useState(
    isPassedDateValid
      ? isoDateStringToDOBFormData(passedDate.toISOString().split("T")[0])
      : {
          day: "",
          month: "",
          year: "",
        }
  );

  function isValidDate(dobData: DOBFormData): boolean {
    return (
      dobData.year.length === 4 &&
      dobData.month.length > 0 &&
      dobData.day.length > 0 &&
      Number(dobData.month) > 0 &&
      Number(dobData.day) > 0
    );
  }

  function isoDateStringToDOBFormData(value: string): DOBFormData {
    return {
      day: value.split("-")[2],
      month: value.split("-")[1],
      year: value.split("-")[0],
    };
  }

  function getClampedData(_dobData: DOBFormData) {
    const today = new Date();
    return clampDate(
      new Date(
        Number(_dobData.year),
        Number(_dobData.month) - 1,
        Number(_dobData.day)
      ),
      new Date(MINIMUM_DATE_OF_BIRTH),
      today
    );
  }

  function isDateWithZeroValid(initialValue: string, currentValue: string) {
    return initialValue.length > 1 && Number(currentValue) === 0;
  }

  function getMaximumDaysInGivenMonth(_dobData: DOBFormData) {
    return _dobData.year.length < 4
      ? 31
      : getDaysInMonth(_dobData.year, _dobData.month);
  }

  function isInitialDateValueEqualToFormattedDateValue(
    initialValue: string | number,
    currentValue: string | number
  ) {
    return Number(initialValue) === Number(currentValue);
  }

  function formatDobData(_dobData: DOBFormData): DOBFormData {
    const today = new Date();
    const initialDob = { ..._dobData };

    _dobData.year = normaliseStringToNumber(_dobData.year);
    _dobData.month = normaliseStringToNumber(_dobData.month);
    _dobData.day = normaliseStringToNumber(_dobData.day);

    const maximumDaysInGivenMonth = getMaximumDaysInGivenMonth(_dobData);

    _dobData.day =
      _dobData.day === ""
        ? _dobData.day
        : String(clampNumber(Number(_dobData.day), 0, maximumDaysInGivenMonth));

    _dobData.month =
      _dobData.month === ""
        ? _dobData.month
        : String(clampNumber(Number(_dobData.month), 0, 12));

    _dobData.year =
      _dobData.year.length < 4
        ? _dobData.year
        : String(
            clampNumber(
              Number(_dobData.year),
              Number(MINIMUM_DATE_OF_BIRTH.split("-")[0]),
              today.getFullYear()
            )
          );

    const clampedDate = getClampedData(_dobData);

    if (isValidDate(_dobData)) {
      _dobData.year = String(clampedDate.getFullYear());

      _dobData.month = isInitialDateValueEqualToFormattedDateValue(
        initialDob.month,
        clampedDate.getMonth() + 1
      )
        ? initialDob.month
        : String(clampedDate.getMonth() + 1);

      _dobData.day = isInitialDateValueEqualToFormattedDateValue(
        initialDob.day,
        clampedDate.getDate()
      )
        ? initialDob.day
        : String(clampedDate.getDate());
    } else {
      _dobData.month = isInitialDateValueEqualToFormattedDateValue(
        initialDob.month,
        _dobData.month
      )
        ? initialDob.month
        : _dobData.month;

      _dobData.day = isInitialDateValueEqualToFormattedDateValue(
        initialDob.day,
        _dobData.day
      )
        ? initialDob.day
        : _dobData.day;
    }

    if (isDateWithZeroValid(initialDob.month, _dobData.month)) {
      _dobData.month = MINIMUM_MONTH;
    }

    if (isDateWithZeroValid(initialDob.day, _dobData.day)) {
      _dobData.day = MINIMUM_DAY;
    }

    return _dobData;
  }

  function onDobFragmentInput(
    fragmentName: DateOfBirthFragmentName,
    fragmentValue: string
  ) {
    const tempDobData = { ...dobData, [fragmentName]: fragmentValue };

    setDobData(formatDobData(tempDobData));
  }

  function onDobFragmentBlur(
    fragmentName: DateOfBirthFragmentName,
    fragmentValue: string
  ) {
    switch (fragmentName) {
      case "day": {
        const day =
          fragmentValue.length > 0 && Number(fragmentValue) === 0
            ? MINIMUM_DAY
            : fragmentValue;

        setDobData((prevState) => ({ ...prevState, [fragmentName]: day }));
        return;
      }
      case "month": {
        const month =
          fragmentValue.length > 0 && Number(fragmentValue) === 0
            ? MINIMUM_MONTH
            : fragmentValue;

        setDobData((prevState) => ({ ...prevState, [fragmentName]: month }));
        return;
      }
      case "year": {
        const year =
          fragmentValue.length > 0 && Number(fragmentValue) < 1900
            ? "1900"
            : fragmentValue;

        setDobData((prevState) => ({ ...prevState, [fragmentName]: year }));
        return;
      }
    }
  }

  const dateFragmentInput = (
    type: DateOfBirthFragmentName,
    maxLength: number
  ) => {
    return (
      <Stack flexGrow={1} spacing={1}>
        {shouldShowLabel === true && (
          <InputLabel htmlFor={inputIDs[type]}>
            {t(`DateOfBirthInput.options.${type}.inputDisplayLabel`)}
          </InputLabel>
        )}
        <TextField
          id={inputIDs[type]}
          error={props.isError}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
            onDobFragmentInput(type, event.target.value)
          }
          onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
            onDobFragmentBlur(type, event.target.value)
          }
          value={dobData[type]}
          inputProps={{
            autoComplete: "off",
            inputMode: "numeric",
            maxLength: maxLength,
            "aria-label": t(`DateOfBirthInput.options.${type}.inputLabel`),
          }}
          size="small"
          disabled={props?.disabled}
          placeholder={
            props?.placeholder
              ? props?.placeholder[`${type}`]
              : t(`DateOfBirthInput.options.${type}.inputPlaceholder`)
          }
          sx={{
            input: {
              ...styleInputDefault,
            },
          }}
        />
      </Stack>
    );
  };

  useEffect(() => {
    props.onChange && props.onChange(dobData, isValidDate(dobData));
  }, [dobData.year, dobData.month, dobData.day]);

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        aria-label={t("DateOfBirthInput.label")}
      >
        {dateFragmentInput("day", 2)}
        {dateFragmentInput("month", 2)}
        {dateFragmentInput("year", 4)}
      </Stack>
      {props.isError && props.errorText && (
        <Stack>
          <Typography variant="caption" color="error">
            {props.errorText}
          </Typography>
        </Stack>
      )}
    </>
  );
}
