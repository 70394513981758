import { useTranslation } from "react-i18next";
import DefaultDialog from "../../../../../../../Common/components/DefaultDialog";
import { Button, Typography } from "@mui/material";

interface PreScanQuestionnaireMoreInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function PreScanQuestionnaireMoreInfoModal({
  isOpen,
  onClose,
}: PreScanQuestionnaireMoreInfoModalProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("WhyAreWeAskingYouThisModal.title")}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      <Typography align="center">
        {t("BinahScanFlow.PreScanFlow.modalContent")}
      </Typography>
    </DefaultDialog>
  );
}
