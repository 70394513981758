import { Stack, Typography } from "@mui/material";
import DateOfBirthInput from "../DateOfBirthInput";
import { useTranslation } from "react-i18next";
import FormInputErrorList from "../FormInputErrorList";

interface Props {
  onChange: Function;
  errors?: Array<string>;
  data?: string;
}

interface ValuePayload {
  day: string;
  month: string;
  year: string;
}

export default function QuestionTypeDateOfBirth({
  onChange,
  data,
  errors,
}: Props) {
  const { t } = useTranslation();

  function onInputChange(valuePayload: ValuePayload, isValid: boolean) {
    onChange({
      data: {
        value: `${valuePayload.year}-${valuePayload.month.padStart(
          2,
          "0"
        )}-${valuePayload.day.padStart(2, "0")}`,
      },
      isValid: isValid,
    });
  }

  return (
    <>
      <Stack spacing={2} justifyContent="space-between">
        <FormInputErrorList errors={errors || []} />
        <Typography variant="h3">
          {t("QuestionTypeDateOfBirth.title")}
        </Typography>
        <DateOfBirthInput value={data} onChange={onInputChange} />
      </Stack>
    </>
  );
}
