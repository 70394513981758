import { forwardRef, ReactElement, Ref, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import CircledBackButton from "../CircledBackButton";
import { Box, Stack } from "@mui/material";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenIframeDialogProps {
  title: string;
  isOpen: boolean;
  onClose: Function;
  url: string;
}

export default function FullScreenIframeDialog(
  props: FullScreenIframeDialogProps
) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(props.isOpen);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  function onClose() {
    setOpen(false);
    props.onClose();
  }

  function onCloseButtonClick() {
    onClose();
  }

  return (
    <Dialog
      fullScreen
      aria-label={props.title}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Stack spacing={1} sx={{ minHeight: "100%" }}>
        <Box sx={{ p: 2 }}>
          <CircledBackButton
            onClick={onCloseButtonClick}
            label={t("common.close")}
          />
        </Box>
        <iframe
          title={props.title}
          src={props.url}
          style={{
            border: "none",
            flexGrow: 1,
            height: "100%",
            width: "100%",
          }}
        ></iframe>
      </Stack>
    </Dialog>
  );
}
