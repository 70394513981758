import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircledBackButton from "../../../../../Common/components/CircledBackButton";
import { convertPxToRem } from "../../../../../../utils";
import IconLoader from "../../../../../Common/components/IconLoader";

interface AwaitCameraPermissionsProps {
  onBackButtonClick: Function;
}

export default function AwaitCameraPermissions({
  onBackButtonClick,
}: AwaitCameraPermissionsProps) {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        p={2}
        sx={{
          backgroundColor: "white",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>
        <Stack
          spacing={2}
          height="100%"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <Box position="relative">
            <IconLoader
              icon="SpinnerIcon"
              className="spin-animation"
              aria-label={t("common.loadingMessage")}
              sx={{
                color: "primary.main",
                fontSize: convertPxToRem(150),
              }}
              strokeWidth="0.5"
            />
            <IconLoader
              icon="CameraIcon"
              color="primary"
              aria-label="Face icon"
              sx={{
                fontSize: convertPxToRem(40),
                left: "50%",
                position: "absolute",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Box>
          <Typography variant="h2">
            {t("BinahScanFlow.awaitingCameraPermissions.title")}
          </Typography>
          {(
            t<any, any, string[]>(
              "BinahScanFlow.awaitingCameraPermissions.body",
              {
                returnObjects: true,
              }
            ) as []
          ).map((text: string, index: number) => (
            <Typography key={String(text + index)}>{text}</Typography>
          ))}
        </Stack>
      </Stack>
    </>
  );
}
