import { trackPageViewV2 } from "@/services/analytics-adapter";
import { HealthCheckProvider } from "@/services/core-api-adapter";
import { useEffect } from "react";
import Default from "./components/Default";
import Intercare from "./components/Intercare";

interface ScanInstructionsProps {
  onConfirm?: () => void;
  onComeBackLater: () => void;
  onBackButtonClick?: () => void;
  provider?: string;
  shouldHideCTAs?: boolean;
}

export default function ScanInstructions({
  onConfirm,
  onComeBackLater,
  onBackButtonClick,
  provider,
  shouldHideCTAs = false,
}: ScanInstructionsProps) {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Health:Health check prescan instructions",
      pageSubSection1: "Health",
      pageSubSection2: "Health:Health check prescan instructions",
      pageCategory: "Health",
    });
  }, []);

  return (
    <>
      {provider === HealthCheckProvider.INTERCARE && (
        <Intercare
          shouldHideCTAs={shouldHideCTAs}
          onConfirm={onConfirm}
          onComeBackLater={onComeBackLater}
          onBackButtonClick={onBackButtonClick}
        />
      )}
      {provider !== HealthCheckProvider.INTERCARE && (
        <Default
          shouldHideCTAs={shouldHideCTAs}
          onConfirm={onConfirm}
          onComeBackLater={onComeBackLater}
          onBackButtonClick={onBackButtonClick}
        />
      )}
    </>
  );
}
