import React from "react";
import { Stack } from "@mui/material";
import theme from "../../../../theme";
import IconLoader from "../IconLoader";

interface InformationBoxProps {
  children?: React.ReactNode;
  Icon?: string;
  width?: number;
  height?: number;
  background?: string;
}

export default function InformationBox({
  children,
  Icon = "LightbulbIcon",
  height = 20,
  width = 20,
  background = theme.palette.primary[100],
}: InformationBoxProps) {
  return (
    <>
      <Stack
        flexDirection="row"
        aria-label="Information box"
        sx={{
          background,
          p: 2,
          borderRadius: 3,
        }}
      >
        <Stack sx={{ mr: 1 }}>
          {
            <IconLoader
              icon={Icon}
              sx={{
                height: height,
                width: width,
              }}
            />
          }
        </Stack>
        <Stack>{children}</Stack>
      </Stack>
    </>
  );
}
